@tailwind base;
@tailwind components;
@tailwind utilities;


body {
    background-color: hsl(60, 21%, 95%);
}

input {
    background-color: white;
}